import React, { useContext } from "react"
import Layout from "../../components/layout"
import ThemeContext from "../../context/ThemeContext"
import SEO from "../../components/seo"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import MainSearch from "../../components/main-search/main-search"
import { graphql } from "gatsby"
import localize from "../../components/localize"


const GenericContentItem = ({ data, pageContext, location }) => {
  let page = data.sanityGenericContentPage
  const { locale, alternateLocaleSlugs } = pageContext

  let { translations, bonuses } = useContext(ThemeContext)

  if (page.casinos && page.casinos.isRaffle)
    bonuses = bonuses.slice().filter(x => x.raffleInformation);

  return (
    <Layout breadcrumbNameOverride={page.breadcrumbName} silo={page.silo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs} title={page && page.seoTitle} description={page && page.seoMetaDescription} />
      <ContentWrapper banner={page.heroImage} useFullBody={true} header={page._rawCombinedBody}
                      headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        {page.casinos && <div className="cards-injected">
          <MainSearch locale={locale} bonuses={bonuses} isRaffle={page.casinos.isRaffle} />
        </div>}
      </ContentWrapper>
    </Layout>
  )
}

export default localize(GenericContentItem)
export const pageQuery = graphql`
  query GenericPageBySlug ($id: String!){
    sanityGenericContentPage (_id: {eq: $id }) {
      ...GenericContent
    }
  }
`
